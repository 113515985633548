import React, { Component, useState, useEffect, useRef } from 'react';
import MorphPhrase from './MorphPhrase';

const Morphext = ({ animation = 'bounceIn', speed = 2000, ...restProps }) => {
  const [indexState, setIndexState] = useState(0);
  const indexRef = useRef(0); //To keep the index into the SetInterval
  const intervals = [];

  useEffect(() => {
    if (restProps.phrases.length < 2) {
      return false;
    }
    startInterval(speed);

    return () => {
      intervals.map(clearInterval);
    };
  }, []);

  const startInterval = (milliseconds) => {
    intervals.push(
      setInterval(() => {
        if (indexRef.current + 1 === restProps.phrases.length) {
          indexRef.current = -1;
          setIndexState(indexRef.current);
        }
        indexRef.current += 1;
        setIndexState(indexRef.current);
      }, milliseconds)
    );
  };

  const renderPhrases = restProps.phrases.map((text, id) => {
    return (
      <MorphPhrase
        key={id}
        phrase={text}
        animation={animation}
        active={id === indexState}
      />
    );
  });

  return <div className="morphext">{renderPhrases}</div>;
};

// Morphext component based in class component
// class Morphext extends Component {
//   state = {
//     index: 0,
//     activePhrase: this.props.phrases[0],
//   };

//   // Set default props
//   static defaultProps = {
//     animation: 'bounceIn',
//     speed: 2000,
//   };

//   componentDidMount() {
//     console.log('entra aqui', this.props);
//     if (this.props.phrases.length < 2) {
//       return false;
//     }
//     console.log('this.state.index', this.state.index);
//     this.setInterval(this.props.speed);
//   }

//   componentWillMount() {
//     this.intervals = [];
//   }
//   setInterval(milliseconds) {
//     console.log('milliseconds', milliseconds);

//     this.intervals.push(
//       setInterval(() => {
//         console.log('this.state.index', this.state.index);
//         console.log('this.props.phrases.length', this.props.phrases.length);
//         if (this.state.index + 1 === this.props.phrases.length) {
//           this.setState({ index: -1 });
//         }
//         this.setState({ index: this.state.index + 1 });
//       }, milliseconds)
//     );
//     console.log('this.intervals', this.intervals);
//   }
//   componentWillUnmount() {
//     console.log('se ejecuta componentWillUnmount');
//     this.intervals.map(clearInterval);
//   }
//   render() {
//     var renderPhrases = this.props.phrases.map(
//       function (text, id) {
//         return (
//           <MorphPhrase
//             phrase={text}
//             animation={this.props.animation}
//             active={id === this.state.index}
//           />
//         );
//       }.bind(this)
//     );

//     return <div className="morphext">{renderPhrases}</div>;
//   }
// }

export default Morphext;