import Morphext from './components/morphext';
import { Animated } from 'react-animated-css';

function bandeau (){
    const phrases = ['Apprendre avec des vidéos', 'Revoir avec des fiches connaissances', 'Vérifier mes connaissances - Quiz'];
return (
    <div className="jumbotron container-fluid text-center text-white change">

<Animated
      animationIn="bounceInUp"
      animationOut="fadeOut"
      isVisible={true}
    >
      <span className="align-middle fw-bolder h2"><Morphext animation="flipInX" speed="2000" phrases={phrases} /></span> 
  <p className="h7">Site dédié aux élèves du cours de Technologie au collège.</p>

<a className="btn btn-outline-light" href="?page=new" role="button">J'ai un code</a>
 </Animated> </div>

 
)
}
export default bandeau;