

function Code (){
 
function handleSubmit(e){
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    document.location.href= window.location.href.replace(document.location.search,'?code='+ formJson.myInput);
      }


return(<div className="codiv border border-dark rounded px-2">
<h2 className="code-title">Saisissez votre code</h2>
<p>Ce code, composé de 5 à 10 caractères, vous est transmis par votre professeur et vous permet d'accéder directement à la ressource désirée</p>


<form method="post" onSubmit={handleSubmit}>
<div className='codentry'>
  <input type="text" className="input-code"  name="myInput" maxLength="10"></input>
  </div> 
  <div className="d-flex justify-content-center">
    <button className="btn btn-outline-secondary" type="submit">Accéder à la ressource</button>
</div></form>

</div>)
}

export default Code;