import {useState} from 'react';
import quiz from './quiz.png';
import logo from './logo.png';
import Bandeau from './bandeau';
import './App.css';
import data from './données.json';
import Acceuil from './accueil.js';
import Code from './code.js';

function App() {
const queryParams = new URLSearchParams(window.location.search);
const  queryTypeDoc= queryParams.get("type")
const  queryClasse= queryParams.get("classe")
const queryDomaine= queryParams.get("domaine")
const queryPage= queryParams.get("page")
const Niveau=queryClasse?"classe"+queryClasse+"e":"";  
const [InVisible,setInVisible]= useState (false);
let Id=(queryTypeDoc?queryTypeDoc:"")+(queryDomaine?queryDomaine:"");
let  queryCode= 0


if (queryParams.has('code')===true)
{Id=queryParams.get('code').toUpperCase();queryCode=1;}
else
{queryCode=0;}
const [query, setquery] = useState('')
function handleChange(e){setquery(e.target.value);setInVisible(true);}
  

//  filtre
  function filter(){
  let filtrerDonnées='';
   if (Niveau==="classe5e")
  {filtrerDonnées=(data.filter(display => display.classe5e.includes("1")&& display.code.includes(Id)));}
    else if  (Niveau==="classe4e")
  {filtrerDonnées=(data.filter(display => display.classe4e.includes("1")&& display.code.includes(Id)));}
  else if  (Niveau==="classe3e")
  {filtrerDonnées=( data.filter(display =>display.classe3e.includes("1") && display.code.includes(Id)));}
   else if  (queryParams.has('code')===true)
   {filtrerDonnées=(data.filter(display =>display.code===(Id)));}
   else if (query!=="")
   {filtrerDonnées= (data.filter(display =>display.connaissance.includes(query)));}
   else 
  {filtrerDonnées= (data.filter(display =>display.code.includes(Id)));}

 const Card = filtrerDonnées.map(filtereddata=>
    { return(
      <div className="mb-4 col-lg-3 col-md-4 col-sm-6 col-12" key={filtereddata.id}>
        <div className="card">
<div className="card-body">
<span className="card__label">code {filtereddata.type==='quiz'? "du " : "de la " }{filtereddata.type}: <strong>{filtereddata.code}</strong></span>
<div>
<a href={filtereddata.url} ><img className="card-img-top card__shadow" src={img(filtereddata.vignettes,filtereddata.type)} alt="vignette"></img></a></div>
<div className="cardcontent"><div className="card-title h5"><div><div className="titlecard">{filtereddata.connaissance}</div>
<div className="classescard">{filtereddata.classe3e==="1"&&(<span className="classe3">3</span>)}{filtereddata.classe4e==="1"&&(<span className="classe4">4</span>)}{filtereddata.classe5e==="1"&&(<span className="classe5">5</span>)}{filtereddata.classe6e===1&&(<span className="classe6">6</span>)}
</div>
</div>
</div>
<p className="card-text mb-2 text-muted"style={{textAlign:'center'}}>{filtereddata.titre!==filtereddata.connaissance && filtereddata.titre}</p>
</div>
</div>
<div className="text-muted card-footer">{Footer (filtereddata.type,filtereddata.url)}
</div>
</div>
      </div> )})
       if(filtrerDonnées.length!==0)
       {return (Card)}
       else if (queryCode===1)
       {return (<div className="info"><div>Désolé, la ressource demandée "<span className="information">{Id}</span>" n'existe pas.</div>
       <a href='/'><button type="button" class="btn btn-outline-primary">Retour</button> </a></div>)}
    else
       {return (<div className="info">Désolé, la ressource demandée "<span className="information">{query}</span>" n'existe pas.</div>)
      }
}
  
    // gestionimage
     function img(data,type){
      if(type==='quiz')
      {return (quiz)}
      else
     {return (data)}
    }
   
   function Footer(doc,lien){
    
    switch (doc) {
      case 'vidéo':
        return(<a className="card-link" href={lien}>Regarder la vidéo</a>);
       
      case 'fiche connaissance':
        return(<a className="card-link" href={lien}>Accéder à la fiche connaissance</a>);
     
      case 'quiz':
        return(<a className="card-link" href={lien}>Accéder au quiz</a>);

       default:
        return(<a className="card-link" href="/">Retour maison</a>);
        
    }

  }
 
    
  function Page() {

        return(
     <div className="m-1 p-3 m-md-3 p-md-3 row">{filter()}
     </div>
    )}

  return (
    <div className="App">
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
  <a className="navbar-brand" href="/"><img className='d-inline-block align-top' src={logo} alt="Sti Bordeaux" width="50" height="50"></img>
    <div className="d-inline-block align-top modif">STI Ressources <div className="subtitle">Technologie Collège</div></div></a>
  
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button> 
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <a className="nav-link" href="/"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
  <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
</svg></a>
        </li>
      </ul>
      
      {(queryPage==="new"
      ||queryCode===1)?'':<form className="d-flex"><input className="form-control me-2" type="search" placeholder="Rechercher" aria-label="Search" onChange={handleChange} value={query}/></form>}
  </div>
  </div>
</nav>
    
<div className="container">
<Bandeau/>


  {(queryPage==="new")?<Code/>:(queryCode===1)||(InVisible)?<Page />:<><Acceuil /><Page /></>}
  </div> 
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom">
  <div className="container-fluid">
  <span className="navbar-brand pied-de-page">Copyright © 2023 Technologie Collège - Académie de Bordeaux. Tous droits réservés.</span></div></nav> 
    </div>
  );
 
}


export default App;
