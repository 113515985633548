import * as Icon from 'react-bootstrap-icons';


function Acceuil (){
const queryParams = new URLSearchParams(window.location.search);
const Niv=queryParams.get("classe");
const Type= queryParams.get("type")
const Dom =queryParams.get("domaine");

const  queryTypeDoc= (queryParams.get("type")) ? `&type=` + Type : '';
const  queryClasse= (queryParams.get("classe")) ? `&classe=` + Niv : '';
const queryDomaine= (queryParams.get("domaine")) ? `&domaine=` + Dom : '';

function choix(param){

  switch(param) {
    case '5':
      window.location='?classe=5'+queryTypeDoc+queryDomaine;
      break;
      case '4':
      window.location='?classe=4'+queryTypeDoc+queryDomaine;
      break;
      case '3':
      window.location='?classe=3'+queryTypeDoc+queryDomaine;
      break;
      case 'V':
        window.location="?type=V"+queryClasse+queryDomaine;
        break;
      case 'FC':
      window.location="?type=FC"+queryClasse+queryDomaine;
      break;
      case 'Q':
      window.location="?type=Q"+queryClasse+queryDomaine;
      break;
      case 'DIC':
      window.location="?domaine=DIC"+queryClasse +queryTypeDoc;
      break;
      case 'OTSCIS':
      window.location="?domaine=OTSCIS"+queryClasse +queryTypeDoc;
      break;
      case 'MSOST':
      window.location="?domaine=MSOST"+queryClasse +queryTypeDoc;
      break;
      case 'IP':
      window.location="?domaine=IP"+queryClasse +queryTypeDoc;
      break;    
    default:
      window.location="";
      break;
  }
}



return ( <div>
{((Niv!==null)||(Dom!==null)||(Type!==null))&&
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
{(Niv!==null) && <select  className="form-select m-2 form-select-sm" value={Niv} onChange={e => choix(e.target.value)}>
  <option  value="5">Je suis en classe de cinquième</option>
  <option value="4">Je suis en classe de quatrième</option>
  <option value="3">Je suis en classe de troisième</option>
</select>}
{(Type!==null) && <select className="form-select m-2 form-select-sm" value={Type} onChange={e => choix(e.target.value)}>
  <option value="V">Je veux apprendre avec des vidéos</option>
  <option value="FC">Je veux Réviser avec des fiches connaissances</option>
  <option value="Q">Je veux m'entrainer avec des quiz</option>
</select>}
{(Dom!==null) && <select className="form-select m-2 form-select-sm"  value={Dom} onChange={e => choix(e.target.value)} >
  <option value="DIC">Dans le domaine DIC</option>
  <option value="OTSCIS">Dans le domaine OTSCIS</option>
  <option value="MSOST">Dans le domaine MSOST</option> 
  <option value="IP">Dans le domaine IP</option>
</select>}
  </div>
</nav>}
{Niv===null && <><h4 className="my-1 my-md-3 md-3">Ressources par niveau</h4>
  <div className="justify-content-md-center row">
    <div className="col"><div className="iconCardType card-text niveau cinq" onClick={() =>choix('5')}><Icon.PersonCircle /><figcaption>5<sup>ème</sup></figcaption></div></div>
    <div className="col"><div className="iconCardType card-text niveau quatre" onClick={() => choix('4')}><Icon.PersonCircle /><figcaption>4<sup>ème</sup></figcaption></div></div>
    <div className="col"><div  className="iconCardType card-text niveau trois" onClick={() => choix('3')}><Icon.PersonCircle /><figcaption>3<sup>ème</sup></figcaption></div></div>  
  </div></>}

  {Type===null && <><h4 className="my-1 my-md-3 py-md-3">Ressources par activité</h4>
<div className="justify-content-md-center row">
<div className="col">
<div variant="outline-secondary" className="card" onClick={() => choix('V')}>
<div className="card-body activity">
<p className="iconCardType card-text">
<Icon.PlayCircleFill/>
</p>
<div className="iconCardText card-title">Apprendre avec des vidéos</div>
</div></div></div><div className="col">
<a href={"?type=FC"+queryClasse+queryDomaine}>
<div variant="outline-secondary" className="card">
<div className="card-body activity">
<p className="iconCardType card-text"><Icon.FileEarmarkPost/></p>
<div className="iconCardText card-title">Réviser avec les fiches connaissances</div></div></div></a>
</div>
<div className="col">
  <div variant="outline-secondary" className="card"onClick={() => choix('Q')}>
  <div className="card-body activity"><p className="iconCardType card-text"><svg fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path></svg></p>
<div className="iconCardText card-title">S’entrainer avec des quiz</div>
</div>
</div>
</div></div></>}

{Dom===null &&<><h4 className="my-1 my-md-3 py-md-3">Ressources par thématique</h4>
  <div className="container text-center">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
  <div className="col"><div onClick={e => choix('DIC')}className="btn dic" role="button"><span className="titre" >DIC</span> <span className="soustitre">Design,innovation,créativité</span></div></div>
  <div className="col"><div onClick={e => choix('OTSCIS')}className="btn otscis" role="button"><span className="titre" >OTSCIS</span> <span className="soustitre">Les objets techniques, les services et les changements induits dans la société</span></div></div>
  <div className="col"><div onClick={e => choix('MSOST')}className="btn msost" role="button"><span className="titre" >MSOST</span> <span className="soustitre">La modélisation et la simulation des objets et systèmes techniques</span></div> </div>
  <div className="col"><div onClick={e => choix('IP')}className="btn ip" role="button"><span className="titre" >IP</span> <span className="soustitre">L’informatique et la programmation</span></div></div>
  </div>
  </div></>}
  {((Niv===null)||(Dom===null)||(Type===null))&&<>
  <h4 className="my-1 my-md-3 py-md-3">Pour un accès rapide à la ressource</h4>
  <div className="container text-center">
  <div className="row">
  <div className="col"><a className="btn btn-outline-dark" href="?page=new" role="button">J'ai un code</a></div>
   </div>
  </div></>}


</div>


) 
}
export default Acceuil;